footer {
    background-color: $black;
    color: $white;
    padding: rem-calc(25 0);
    font-size: rem-calc(14);

    .milan {
        text-align: left;
        color: #c51431;
        font-size: rem-calc(25);
        line-height: 0.8;
        font-weight: $font-weight-extrabold;
        //padding-top: rem-calc(30);
        padding-bottom: rem-calc(9);
        // letter-spacing: rem-calc(-3);
        // background: -webkit-linear-gradient(right, $rouge, #f5acb9, $rouge);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        // position: relative;
        // animation: gradient 5s ease infinite;

        // @-webkit-keyframes gradient {
        //     0% { background-position: -500px; }
        //     100% { background-position: 500px; }
        //   }

    }

    .milan-date {
        color: $white;
        font-size: rem-calc(15);
        line-height: 1;
        
        font-weight: $font-weight-normal;
        letter-spacing: rem-calc(0);

            span {
                color: $white;
            }
    }

    .milan-meets {
        color: $white;
        font-size: rem-calc(20);
        line-height: 1.2;
        font-weight: $font-weight-extrabold;
        padding-bottom: rem-calc(2);
        letter-spacing: rem-calc(-1);
    }

    .milan-invited {
        color: $white;
        text-transform: uppercase;
        //padding-bottom: rem-calc(20);
    }

        a { color: $rouge;
            &:hover {
                color: $white;
            }
            &.facebook { display:block; height:40px; width:40px; background:url('../images/reseau.png') no-repeat left top; -webkit-transition: all 150ms ease-in-out;-moz-transition: all 150ms ease-in-out;-ms-transition: all 150ms ease-in-out;-o-transition: all 150ms ease-in-out;transition: all 150ms ease-in-out;
                &:hover { background:url('../images/reseau.png') no-repeat left -42px; -webkit-transition: all 150ms ease-in-out;-moz-transition: all 150ms ease-in-out;-ms-transition: all 150ms ease-in-out;-o-transition: all 150ms ease-in-out;transition: all 150ms ease-in-out;}
            }
            &.twitter {display:block; height:40px; width:40px; background:url('../images/reseau.png') no-repeat -51px top; -webkit-transition: all 150ms ease-in-out;-moz-transition: all 150ms ease-in-out;-ms-transition: all 150ms ease-in-out;-o-transition: all 150ms ease-in-out;transition: all 150ms ease-in-out;
                &:hover {background:url('../images/reseau.png') no-repeat -51px -42px; -webkit-transition: all 150ms ease-in-out;-moz-transition: all 150ms ease-in-out;-ms-transition: all 150ms ease-in-out;-o-transition: all 150ms ease-in-out;transition: all 150ms ease-in-out;}
            }
            &.google {display:block; height:40px; width:40px; background:url('../images/reseau.png') no-repeat -2px top; -webkit-transition: all 150ms ease-in-out;-moz-transition: all 150ms ease-in-out;-ms-transition: all 150ms ease-in-out;-o-transition: all 150ms ease-in-out;transition: all 150ms ease-in-out;
                &:hover {background:url('../images/reseau.png') no-repeat -137px -42px; -webkit-transition: all 150ms ease-in-out;-moz-transition: all 150ms ease-in-out;-ms-transition: all 150ms ease-in-out;-o-transition: all 150ms ease-in-out;transition: all 150ms ease-in-out;}
            }
            &.linkedin {display:block; height:40px; width:40px; background:url('../images/reseau.png') no-repeat -154px top; -webkit-transition: all 150ms ease-in-out;-moz-transition: all 150ms ease-in-out;-ms-transition: all 150ms ease-in-out;-o-transition: all 150ms ease-in-out;transition: all 150ms ease-in-out;
                &:hover {background:url('../images/reseau.png') no-repeat -154px -42px; -webkit-transition: all 150ms ease-in-out;-moz-transition: all 150ms ease-in-out;-ms-transition: all 150ms ease-in-out;-o-transition: all 150ms ease-in-out;transition: all 150ms ease-in-out;}
            }
            &.viadeo {display:block; height:40px; width:40px; background:url('../images/reseau.png') no-repeat -276px top; -webkit-transition: all 150ms ease-in-out;-moz-transition: all 150ms ease-in-out;-ms-transition: all 150ms ease-in-out;-o-transition: all 150ms ease-in-out;transition: all 150ms ease-in-out;
                &:hover {background:url('../images/reseau.png') no-repeat -276px -42px; -webkit-transition: all 150ms ease-in-out;-moz-transition: all 150ms ease-in-out;-ms-transition: all 150ms ease-in-out;-o-transition: all 150ms ease-in-out;transition: all 150ms ease-in-out;}
            }
            &.youtube {display:block; height:40px; width:40px; background:url('../images/reseau.png') no-repeat -256px top; -webkit-transition: all 150ms ease-in-out;-moz-transition: all 150ms ease-in-out;-ms-transition: all 150ms ease-in-out;-o-transition: all 150ms ease-in-out;transition: all 150ms ease-in-out;
                &:hover {background:url('../images/reseau.png') no-repeat -256px -42px; -webkit-transition: all 150ms ease-in-out;-moz-transition: all 150ms ease-in-out;-ms-transition: all 150ms ease-in-out;-o-transition: all 150ms ease-in-out;transition: all 150ms ease-in-out;}
            }
            &.instagram {display:block; height:40px; width:40px; background:url('../images/reseau.png') no-repeat -302px top; -webkit-transition: all 150ms ease-in-out;-moz-transition: all 150ms ease-in-out;-ms-transition: all 150ms ease-in-out;-o-transition: all 150ms ease-in-out;transition: all 150ms ease-in-out;
                &:hover {background:url('../images/reseau.png') no-repeat -302px -42px; -webkit-transition: all 150ms ease-in-out;-moz-transition: all 150ms ease-in-out;-ms-transition: all 150ms ease-in-out;-o-transition: all 150ms ease-in-out;transition: all 150ms ease-in-out;}
            }   
        
        }

        img {
            &.logo-footer-reloaded {
                max-width: rem-calc(160);
                height: auto;
            }

            &.logo-pixelup{
                max-width: rem-calc(50);
                height: auto;
            }
        }

        ul { display: flex; margin:0;
            li {
                list-style: none;
                padding: rem-calc(5);
            }
        }

        h4 {
            font-family: "Montserrat", sans-serif;
            font-weight: $font-weight-extrabold;
            font-size: rem-calc(15);
            margin-top: rem-calc(20);
            color: $rouge;
        }

        .copyright {
            p {
                margin: 0;
                padding: 0;
            }
        } 
}
;@import "sass-embedded-legacy-load-done:726";