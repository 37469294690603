/* -----------------------------------------
   Header
----------------------------------------- */

header {
    background-image: url(../assets/bg-header-2025.jpg);
    background-size: contain;
    background-position: right bottom 40%;
    background-repeat: no-repeat;
    padding-top: rem-calc(50);

    @media #{$medium-only} {
        background-position: right -50% bottom !important;
    }

    div {
        &#header-top {
            text-align: center;


            #visu {
                font-family: "Montserrat", sans-serif;
                text-align: center;


                a {
                    &.backhome {
                        display: block;
                        //background-color: rgba(255, 255, 255, 0.4);

                        .milan {
                            text-align: center;
                            color: $rouge;
                            font-size: rem-calc(107);
                            line-height: 0.8;
                            font-weight: $font-weight-extrabold;
                            padding-top: rem-calc(30);
                            padding-bottom: rem-calc(9);
                            letter-spacing: rem-calc(-3);
                            // background: -webkit-linear-gradient(right, $rouge, #580e1a, $rouge);
                            // -webkit-background-clip: text;
                            // -webkit-text-fill-color: transparent;
                            // position: relative;
                            // animation: gradient 5s ease infinite;

                                // @-webkit-keyframes gradient {
                                //     0% { background-position: -500px; }
                                //     100% { background-position: 500px; }
                                // }

                                @media #{$small-only} {
                                    font-size: 14vw;
                                }
                                
        
                        }

                        .milan-meets {
                            color: $black;
                            font-size: rem-calc(42);
                            line-height: 1.2;
                            font-weight: $font-weight-extrabold;
                            padding-bottom: rem-calc(2);
                            //background-color: rgba(255, 255, 255, 0.4);
                            letter-spacing: rem-calc(-1);

                            @media #{$small-only} {
                                font-size: 7vw;
                            }
                            
                        }
        
                        .milan-invited {
                            color: $black;
                            text-transform: uppercase;
                            padding-bottom: rem-calc(25);
                        }
                    }
                
                    #countdown {
                        display: flex;
                        justify-content: center;
                        align-content: center;
                        align-items: center;
                        font-size: rem-calc(18);
                        font-weight: 800;
                        text-align: center;
                        margin-bottom: rem-calc(25);
    
                            .time-unit {
                                display: flex;
                                //flex-direction: column;
                                align-items: center;
                                margin: rem-calc(0 10);
                                color: $rouge;

                                    .label {
                                        background: none;
                                        color: $black;
                                        font-weight: 200;
                                        font-size: rem-calc(13);
                                        padding: rem-calc(0 0 0 5);
                                    }

                               
                            }
                    }
                }

                img {
                    &.logo-eao {
                        max-width: rem-calc(400);
                        height: auto;
                        margin-top: rem-calc(5);
                    }
                    &.logo-reloaded {
                        max-width: rem-calc(600);
                        height: auto;
                        margin-top: rem-calc(10);
                    }
                    &.online {
                        max-width: rem-calc(100);
                        height: auto;
                        margin-right: rem-calc(30);
                        margin-bottom: rem-calc(10);
                    }
                }
                
            }

            #logo {
                text-align: right;
                padding-top: 1em;

                @media only screen and (max-width: 63.9375em) {
                    text-align: center;
                }

                .my-calandar {
                    a {
                        display: inline-block;
                        font-family: 'Montserrat', sans-serif;
                        padding: rem-calc(10 20);
                        color: $white;
                        font-size: rem-calc(14);
                        line-height: 1;
                        background-color: $black;
                        font-weight: $font-weight-blod;
                        letter-spacing: rem-calc(1);
                        margin-right: rem-calc(50);
    
                        @media only screen and (max-width: 63.9375em) {
                            font-size: rem-calc(14);
                            margin-right: 0;
                        }
    
                            span {
                                color: $black;
                            }
                    }
                }

                    .milan-date {
                        display: inline-block;
                        font-family: 'Montserrat', sans-serif;
                        padding: rem-calc(15 20);
                        color: $white;
                        font-size: rem-calc(26);
                        line-height: 1;
                        background-color: $rouge;
                        font-weight: $font-weight-extrabold;
                        letter-spacing: rem-calc(2);
                        margin-right: rem-calc(50);

                        @media only screen and (max-width: 63.9375em) {
                            font-size: rem-calc(18);
                            margin-right: 0;
                        }

                            span {
                                color: $black;
                            }
                    }
            }
        }
    }
}

section {
    &.translate {
        background-color:$rouge;
        padding-top:rem-calc(5);
        color: $white;
        text-align:center;

        @media only screen and (max-width: 63.9375em) {
                background-color:$black;
                font-size: rem-calc(14);
                padding-bottom: rem-calc(20);

                    img {
                        display: block;
                        margin: auto;
                    }

                    strong {
                        display: block;
                    }
            }
    }
}

@media only screen and (max-width: 63.9375em) {

    header {
        background: url(../images/bg-header-2025-mobile.jpg) right bottom rem-calc(-25) no-repeat;
        background-size: contain;
        border-bottom: solid rem-calc(10) $rouge;
        padding-top: rem-calc(20);

        div {
            &#header-top {
                padding-bottom: rem-calc(230);

                #visu {
                    background: none;
                    .text-right {
                        text-align: center !important;
                    }

                    img {
                        &.logo-eao {
                            max-width: rem-calc(260);
                            height: auto;
                        }
                        &.online {
                            margin: rem-calc(15 0 0 0);
                        }
                    }
                    .milan {
                        font-size: 19vw;
                    }

                    .milan-meets {
                        font-size: rem-calc(25);
                    }

                    .milan-invited {
                        padding-bottom: 0;
                    }
                }
            }
        }
    }
}


header #logo img.logo-eao {
    max-width: rem-calc(478);
    height: auto;
}



header .madrid {
    text-transform: uppercase;
    font-size: 1.75rem;
    line-height: 1.1;
    margin-top: 1rem;
}
header .date {
    text-transform: uppercase;
    color: #828084;
    font-size: 1.35rem;
    font-weight: 300;
    line-height: 1.1;
}
header .osseointegration {
    font-family: "Montserrat", sans-serif;
    background: url(../images/top-header.png) 3% 19% no-repeat;
    padding-top: 1.5em;
    color: #000;
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 1;
    text-align: left;
}
header .congreso {
    background: url(../images/top-header-right.png) right top no-repeat;
    padding-top: 1.5em;
    text-transform: uppercase;
    color: #828084;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.3;
    text-align: right;
}
header .date span {
    text-transform: uppercase;
    color: #c51431;
    font-weight: 600;
}

header .follow {
    text-align: left;
    color: #c51431;
    font-size: 1.3rem;
    line-height: 0.7;
}
header .block-vienna {
    font-size: rem-calc(23);
    line-height: 1;
    margin-bottom: 2em; /*text-transform:uppercase;*/
    text-align: left;
    font-weight: 600; /*background:rgba(197,20,49, 0.9);*/
    padding: rem-calc(5 5 5 0);
    color: #000;
}
header .block-vienna span {
    font-weight: 300;
}
header .block-vienna sup {
    font-size: 0.65rem;
}
header .block-vienna div.invited {
    font-size: 1.2rem;
    margin-top: 1.5rem;
}

@media only screen and (max-width: 40em) {
    section#edito-news .news,
    section#edito-news .edito {
        text-align: justify;
    }
    header .vienna {
        text-align: left;
        color: #c51431;
        font-size: 4.5rem;
        line-height: 0.6;
        font-weight: 800;
        padding-top: 0.3em;
        padding-left: 0;
    }
    header #logo {
        text-align: center;
    }
    
    header .vienna {
        padding-top: 1.8rem;
        text-align: left; /*background: rgba(255,255,255,0.8);*/
    }
    header .block-vienna {
        text-align: left;
        margin-bottom: 12em;
        padding: 0.4em 1em 1em 0;
    }
    header #visu {
        background: url(../images/bg-header.jpg) right rem-calc(81) no-repeat;
        height: inherit;
    }

    header img.saveDate {
        float: none;
    }
    header .congressDate,
    header .date,
    header .madrid {
        text-align: center;
    }
    header .date {
        padding: 0;
        margin-top: 0;
    }
    header #visu .txt-lisb {
        padding: inherit !important;
        padding-top: 0 !important;
    }
    /*header .osseointegration { text-align:center; background:none; padding-top:0;}
    header .congreso { text-align:center; background:none;padding-top:0;}*/
}

@media only screen and (min-width: 40.063em) and (max-width: 64.063em) {
    header img.saveDate {
        float: none;
    }
    header .congressDate,
    header .date,
    header .madrid {
        text-align: center;
    }
}

;@import "sass-embedded-legacy-load-done:725";