/* Feuille de style chargée pour la version mobile du site ET la version desktop */
/* Debugger */
#debug{font-size:10px;background:white;padding:0 2px;max-width:100%;text-align:right;position:fixed;bottom:0;right:0;z-index:64536;border:1px solid black;}
#debug pre {white-space:nowrap;display:none;overflow-x: scroll;text-align:left;}
#debug span { display: inline; }
#debug:hover { }
#debug:hover pre{ display:block; }

/* Diverses corrections foundation */
.label a, .alert-box a {
  color: white;
}

.gauche:not(.seccode), img[align=left]:not(.seccode) {
  margin: 0 15px 15px 0;
  float: left;
  clear: left;
}

.droite:not(.seccode), img[align=right]:not(.seccode) {
  margin: 0 0 15px 15px;
  float: right;
  clear: right;
}

.centre:not(.seccode) {
  clear: both;
  margin: 15px auto;
  display: block;
}
figure {
    margin: 0;
}
figure.th {
  line-height: normal;
  background: white;
}

figure.th figcaption {
  font-size: smaller;
  text-align: center;
  padding: 5px;
  background: #f4f4f4;
  border: 1px dotted #dadada
}

.top-bar-section ul ul{display: none;}

.top, .print, .rss{
  display: block;
  width: 45px;
  height: 45px;
  text-indent: -9999px;
}
.top{background: url(../images/top.png) no-repeat center;}
.print{background: url(../images/print.png) no-repeat center;}
.rss{background: url(../images/rss.png) no-repeat center;}

.btrouge {
  background-color: $rouge;
  &:hover {
      background-color: $black;
  }
}


form {
    fieldset {
        @include shadow-box;
        padding: rem-calc(15);
        margin-bottom: rem-calc(30);

        &#soumettre {
            legend {
                text-align: left !important;
            }
        }
    }

    legend {
        &.small-12 {
            width: auto !important;
            background-color: $white;
            margin-left: 1em;
            color: $rouge;
            border: solid rem-calc(1);
            font-weight: 600;
            font-size: rem-calc(25);
            border-radius: rem-calc(25);

        }

    }

    .seccode {
        display: none;
    }
}


/* Pagination */

ul.pagination li.current a, ul.pagination li.current button{
  color: #fff!important;
  font-weight: 400;
  border-radius: 0;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.is-dropdown-submenu.js-dropdown-active{

    animation-name : smoothshow;
    animation-duration: .7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;

  }

  @keyframes smoothshow {
    from {display:none;height:0;opacity: 0}
    to {display:block;height:auto;opacity:1;}
  }

  .contenu-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: stretch;
    align-content: center;

        div {
            margin: rem-calc(5);
        }
}

img {
    &.logo-pixelup {
        max-width: rem-calc(80);
        height: auto;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Montserrat", sans-serif;
    color: $rouge;
    font-weight: $font-weight-extrabold;
}

.under {
    font-size: rem-calc(55);
    color: $black;
    margin: rem-calc(200 0);
    text-align: center;
     
        &:before {
            content: none !important;
        }
}

.px-flex {
    display: flex;
    //justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .px-item-flex {
        margin: rem-calc(10);
    }
}

.px-flex-column {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;

    .px-item-flex {
        margin: rem-calc(10);
        text-align: left;
        line-height: 1.2;

        span {
            font-size: rem-calc(13);
            color: $dark-gray
        }
        strong {
            &.titre-m {
                color: $rouge;
            }
        }
    }
}

div {
    &#titre-filet {
        display: flex;
        align-items: center; /* aligne les éléments au centre verticalement */
        justify-content: center;
        width: 100%;

        @media only screen and (max-width: 63.9375em) {
            flex-direction: column;
        }

        h1 {
            &.titre-page {
                color: $rouge;
                text-transform: uppercase;
                margin: 0 rem-calc(10);
            }
        }

        .filet {
            flex-grow: 1;
            height: 1px; /* Vous pouvez ajuster l'épaisseur de la ligne ici */
            background-color: $rouge; /* Vous pouvez ajuster la couleur de la ligne ici */

            @media only screen and (max-width: 63.9375em) {
                width: 100%;
                margin: rem-calc(10) 0;
            }
        }
    }
}

h2 {
    color: $rouge;
}
h3,
h4,
h5,
h6 {
    color: $rouge;
    font-weight: $font-weight-light !important;
}

.rte {
    h2, h3, h4, h5, h6 {
        position: relative;
        margin-top: rem-calc(40);
        margin-bottom: rem-calc(15);
        padding-bottom: rem-calc(8);

         &:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: rem-calc(100);
            height: rem-calc(4);
            background: grey;

            @include breakpoint(small only) {
                left: 50% !important;
                margin-left: rem-calc(-50) !important;
             }
         }

         &.position-center {
             position: relative;
             text-align: center;
             margin-top: rem-calc(40);
             margin-bottom: rem-calc(15);
             padding-bottom: rem-calc(8);
     
              &:before {
                 content: "";
                 position: absolute;
                 bottom: 0;
                 left: 50% !important;
                 margin-left: rem-calc(-50) !important;
                 width: rem-calc(100);
                 height: rem-calc(4);
                 background: grey;
              }
         }

         &.no-trait {
            &:before {
                content: none;
            }
         }

         &.no-trait-no-marge {
            margin-top: 0;
            padding-bottom: 0;

            &:before {
                content: none;
            }
         }
    }

    h2 {
        font-weight: $font-weight-medium !important;
    }

    .lazy-youtube-player {
        
        svg {
            width: rem-calc(80);
            height: auto;
        }
    }

}
  /* breadcrumbs */

ul {
  &.breadcrumbs {
      float: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      margin-bottom: rem-calc(30);
  
        li {
          margin: rem-calc(5);

              a {
                  color: $black;

                      &:hover {
                          color: $rouge;
                      }
              }
        }
  }
}

img {
  &.round {
      border-radius: 100% 100% 0 100%;
      -webkit-border-radius: 100% 100% 0 100%;
      box-shadow: rem-calc(3 3 10 0) rgba($black, 0.2);
      border: solid rem-calc(2) $white;
  }
}

.panel {
  @include shadow-box;
  padding: rem-calc(15);
  border: solid rem-calc(1) $gris-pale;
  text-align: center;
}

.block-ombre {
  @include shadow-box;
  padding: rem-calc(15);
  border-top: solid rem-calc(10) $noir;
}

a {
  color: $rouge;
  &:hover {
      color: rgba($rouge, 0.7);
  }
}

@media print, screen and (min-width: 40em) {
  .reveal {
      width: 1000px;
      max-width: 75rem;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .tbl-price td {
      width: 100%;
      display: block;
      text-align: right;
      font-size: rem-calc(14);
      border: solid 1px #999;
  }

  .tbl-price td:first-child {
      text-align: left;
  }

  .tbl-price td:before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .tbl-price td {
      width: 50%;
      display: table-cell;
      text-align: center;
  }

  .tbl-price td:first-child {
      text-align: left;
  }

  .tbl-price td:before {
      content: "";
      float: none;
      font-weight: normal;
  }

  .tbl-price tr:first-child td:before {
      content: "";
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .tbl-price td {
      width: auto;
      display: table-cell;
      text-align: center;
  }

  .tbl-price td:first-child {
      text-align: center;
  }

  .tbl-price td:before {
      content: "";
      float: none;
      font-weight: normal;
  }

  .tbl-price tr:first-child td:before {
      content: "";
  }
  
}

section {
  &#contenu-principal {
      padding-top: rem-calc(40);
      //background-image: url(../assets/frise.gif);
      //background-repeat: repeat-x;
      //background-position: center top rem-calc(-50);
  }
}

div {
  &.call-to-action {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-content: flex-start;
      margin: rem-calc(25 0);
      padding: rem-calc(40);
      background-color: $rouge;
      border-radius: rem-calc(100 100 0 100);
      color: $white;
      line-height: 1.1;

      @media #{$small-only} {
          flex-direction: column;
          align-content: center;
          flex-wrap: wrap;
          text-align: center;
      }

          div {
              margin: rem-calc(0 30 0 0);
              @media #{$small-only} {
                  margin: 0;
              }

              &:last-child {
                  margin: 0;
              }

  
              &.txt-calltoaction {

                  h3 {
                      color: $white;
                      font-weight: 800 !important;
                      font-size: rem-calc(28);
                      margin-bottom: rem-calc(15);
                  }

                  .photo-text {
                      display: flex;
                      align-items: flex-end;

                      @media #{$small-only} {
                          flex-direction: column;
                          align-items: center;
                          align-content: center;

                      }

                      div {
                          margin: rem-calc(0 30 0 0);

                          @media #{$small-only} {
                              text-align: center;
                              margin: 0;

                              img {
                                  margin-bottom: rem-calc(15);
                              }
                          }

                          &:last-child {
                              margin: 0;
                          }

                          p {
                              line-height: 1.2;
                              margin: 0;
                          }
                          em {
                              font-style: normal;
                              //text-decoration: underline;
                              color: $black;
                              font-weight: 600;
                          }
                          a {
                              color: $white;

                              &:hover {
                                  color: $black;
                              }
                          }

                      }
                  }
                 
              }
          }

          

  }
}

form {
  fieldset {
      &#soumettre {
          legend { text-align: center; }
      }
  }
  #rremboursement-submit {
      button { border-radius: rem-calc(30);}
  }

  #rfichier_1, #rfichier_2, #rfichier_3, #rfichier_4, #rfichier_5 {
      label {
          font-weight: 600;
          
      }
  }
  #rfichier_1 {
      label {
          color: $rouge;

      }
  }
}




;@import "sass-embedded-legacy-load-done:715";