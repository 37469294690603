
nav {
    &#navigation {
        background-color: $noir;
        border-top: solid 5px $rouge;
       // margin-bottom: rem-calc(50);
        

        ul {
            &.menu { justify-content: center;
                li {
                    &.index { width: rem-calc(51);
                            
                        a {
                            background-image: url(../images/home-picto.svg) !important;
                            background-color: transparent !important;
                            background-repeat: no-repeat !important;
                            background-position: center rem-calc(9) !important;
                            background-size: 71%;
                            font-size: 0px;
                            display: block;
                            height: rem-calc(54);
                            padding: 0;
                        }
                    }
                    

                    a {
                        color: $white;
                        font-weight: 600;
                        text-transform: uppercase;
                        font-size: rem-calc(20);
                        font-family: "Montserrat", sans-serif;
                        padding: rem-calc(17 18);
                        height: initial;

                            &:hover {
                                color: $white;
                                background-color: $rouge !important;
                                &:after{ border-color: $white transparent transparent !important; }
                            }
                            
                    }

                    &.is-active {
                        background-color: $rouge;
                        & > a {
                            color: $white !important;
                        }
                    }

                    ul {
                        &.is-dropdown-submenu { width: rem-calc(300); border: none; padding: rem-calc(0 15); border-radius: rem-calc(0 0 8 8);
                            li { 
                                border-bottom: solid rem-calc(1) $white; 
                                padding-left: 0;
                                //border-color: $gris-clair; border-top: none;  border-bottom: inherit; padding-left:0;

                                a {
                                    font-size: rem-calc(18) !important;
                                    text-transform: inherit;
                                    font-weight: $font-weight-normal;
                                    
                                    padding-top: rem-calc(10);
                                    padding-bottom: rem-calc(10);
                                    padding-left: 0;

                                    &:hover {
                                        color: $black;
                                    }
                                    
                                }
                                &:last-child { 
                                    border: none;
                                    padding-bottom: rem-calc(15);
                                    border-radius: rem-calc(0 0 8 8);
                                }
                                
                            }
                        }
                    }
                }
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:724";