.exposant {

    .ombrage-sponsor {
        //box-shadow: rem-calc(0 0 15 0) rgba(0,0,0,.4);
        border-radius: rem-calc(30);
        border:solid rgba($gris-clair, .4) rem-calc(1);
        background-color: rgba($gris-clair, .2);
        padding: rem-calc(15);
    }

    h2 {
        line-height: .7;
        font-weight:600;
        margin-bottom:rem-calc(2);

        small {
            font-size: rem-calc(16);
            color: gray;
        }
    }

    img {
        @include shadow-box;
        transition: all 500ms;
        width: rem-calc(196);
        height: auto;

        &:hover {
            transition: all 500ms;
            transform: scale(1.1);
            filter: grayscale(100%);
        }
    }
}

;@import "sass-embedded-legacy-load-done:728";