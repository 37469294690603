/* -----------------------------------------
   Configuration de foundation
   Se référer au fichier : foundation-sites/scss/settings/_settings.scss
----------------------------------------- */
/* exemple :
$foundation-palette: (
  primary: #b32a85,
  secondary: #15c55e,
  success: #000000,
  warning: #ffc31f,
  alert: #4d0fc0,
);
$breakpoint-classes: (small medium large xlarge);
*/

$global-font-size: 110%;
$global-width: rem-calc(1300);

/* color */
$bleu:          #008CBA;
$bleu-fonce:    #21316a;
$bleu-claire:   #008CBA;
$gris:          #949597;
$gris-clair:    #e5e4e4;
$gris-pale:     #f5f5f5;
$vert:          #4c8d40;
$bleu-fonce:    #004070;
$bleu-clair:    #007dc5;
$noir:          #020202;
$rouge:         #c71c38;

$font-weight-extra-extralight: 100;
$font-weight-extralight: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 600;
$font-weight-blod: 700;
$font-weight-extrabold: 900;

// We use these to define default font stacks
$font-family-sans-serif: 'Raleway', sans-serif;

/* MENU */
$dropdownmenu-background: $black;
$dropdownmenu-submenu-background: $rouge;
$dropdownmenu-arrow-color: $rouge;
$dropdown-menu-item-color-active: $rouge;
$dropdown-menu-item-background-active: $rouge;
/*
$dropdownmenu-arrows: true;
$dropdownmenu-arrow-size: 6px;
$dropdownmenu-arrow-padding: 1.5rem;
$dropdownmenu-min-width: 200px;
$dropdownmenu-background: null;
$dropdownmenu-submenu-background: $white;
$dropdownmenu-padding: $global-menu-padding;
$dropdownmenu-nested-margin: 0;
$dropdownmenu-submenu-padding: $dropdownmenu-padding;
$dropdownmenu-border: 1px solid $medium-gray;
*/

/* BUTTON */
$button-background: $black;
$button-background-hover: scale-color($rouge, $lightness: -15%);

/* -----------------------------------------
 Import de foundation - Modifier les includes si besoin
----------------------------------------- */

// Global configuration
$global-flexbox: true;
$xy-grid: true;
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;
@include foundation-xy-grid-classes;
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
@include foundation-prototype-classes;

// Generic components
@include foundation-button;
//@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-switch;
@include foundation-table;

// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
//@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
//@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;

// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
//@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
//@include foundation-title-bar;
@include foundation-top-bar;
/*rien ci-dessous // fin du fichier */

;@import "sass-embedded-legacy-load-done:712";