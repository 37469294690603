
#menuResponsive {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgba($black,.9);
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: rem-calc(100);
    text-align: center;

        ul { display: flex; flex-direction: column;
            li { padding: rem-calc(5 0);
                a {
                    font-weight: $font-weight-extrabold;
                    text-transform: uppercase;

                        &:hover {
                            color: $rouge;
                        }
                }

                /* Sous menu */
                ul {
                    li {
                        padding: rem-calc(1 0);

                            a {
                                font-weight: $font-weight-normal;
                                text-transform: inherit !important;
                                font-size: rem-calc(18);

                                    &:hover {
                                        color: $rouge;
                                    }
                            }

                            &:last-child {
                                margin-bottom: rem-calc(20);
                            }
                    }
                }
            }
        }
        

        a {
            padding: 8px 8px 8px 32px;
            text-decoration: none;
            font-size: 25px;
            color: $white;
            display: block;
            transition: 0.3s;
            &:hover {
                color: $bleu-clair;
            }
        }

        .closebtn {
            position: absolute;
            top: 0;
            right: 25px;
            font-size: 36px;
            margin-left: 50px;
        }
}
/*
ul {
    &.submenu {
        li {
            padding-left: rem-calc(15);
            a {
                font-size: rem-calc(17);
            }
        }
    }
}

#main {
    transition: margin-left 0.5s;
    padding: 0px !important;
}

@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }
    .sidenav a {
        font-size: 18px;
    }
}
*/

/* Barre pied de page pour menu mobile */
.container_footer_menu {
    position: relative;
    z-index: 999;

        .footerMenu {
            background: $rouge;
            position: fixed;
            height: rem-calc(70);
            bottom: 0;
            width: 100%;
            box-shadow: 1px 1px 1px $secondary-color;
            line-height: 1.15;

            .menu-block-mobile {
                text-align: center;
                color: $white;
                padding-bottom: 30px;

                    a { color: $white; }

                    span {
                        font-size: 10vw !important;
                        cursor: pointer;

                            &:first-child {
                                font-size: 12vw !important;
                            }
                    }
            }

            .mail {
                img {
                    margin-top: rem-calc(15);
                }
            }
            .bt_top {
                img {
                    margin-top: rem-calc(15);
                }
            }
        }
}

;@import "sass-embedded-legacy-load-done:723";