/**
  * Usages :
   @include texte( rem-calc(12), #FF0000, 400, 0.05rem, rem-calc(12), $body-font-color, center );
   @include texte( rem-calc(12), #000000 );
   @include texte( rem-calc(12), $align: right, $color: green );
   @include texte( $align: right, $color: green, $size: 12 );
*/
@mixin texte(
    $size: null,
    $color: null,
    $weight: null,
    $spacing: null,
    $height: null,
    $family: null,
    $align: null,
    $transform: null
) {
    font-size: $size;
    color: $color;
    font-weight: $weight;
    letter-spacing: $spacing;
    line-height: $height;
    font-family: $family;
    text-align: $align;
    text-transform: $transform;
}

/**
 * Importation de Foundation 6
*/
@mixin import-foundation(
  $flex: true,
  $prototype: false,
  $xy-grid: $xy-grid
) {
  @if $flex {
    $global-flexbox: true !global;
  }

  @if $xy-grid {
    $xy-grid: true !global;
  }


  // Grids
  @if not $flex {
    @include foundation-grid;
  }
  @else {
    @if $xy-grid {
      @include foundation-xy-grid-classes;
    }
    @else {
      @include foundation-flex-grid;
    }
  }

  // Helpers
  @include foundation-float-classes;
  @if $flex { @include foundation-flex-classes; }
  @include foundation-visibility-classes;
  @if $prototype { @include foundation-prototype-classes; }
}

@mixin transition {
  -webkit-transition: all 500ms ease-in-out; -moz-transition: all 500ms ease-in-out; -ms-transition: all 500ms ease-in-out; -o-transition: all 500ms ease-in-out; transition: all 500ms ease-in-out;
}
@mixin transform {
  -moz-transform: scale(1.1); -webkit-transform: scale(1.1); -o-transform: scale(1.1); -ms-transform: scale(1.1); transform: scale(1.1); @include transition;
}

@mixin shadow-box {
box-shadow: rem-calc(0 0 10 0) rgba($black, .1); -webkit-box-shadow: rem-calc(0 0 10 0) rgba($black, .1);
}

@mixin black-and-white {
  filter: grayscale(100%);
}

;@import "sass-embedded-legacy-load-done:714";