body {
    &.junior-committee-sessions {
        #preview {
            .rte {
                ul {
                    list-style-position: outside;
                    li {
                        ul {
                            list-style-position: outside;
                            list-style-type: circle !important;
                        }
                    }
                }
            }
        }
    }
}
;@import "sass-embedded-legacy-load-done:730";