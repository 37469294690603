section {
    &.liste-forum-industrie, &.liste-session-industrie  {

        @include breakpoint(small only) {
            text-align: center;
        }

        .onsite {
            @include shadow-box;
            border-radius: rem-calc(15);
        }

        h2 {
            font-weight: 500;
                @include breakpoint(small only) {
                   text-align: center;
                }
        }

        .logo-industrie {
            @include breakpoint(small only) {
                text-align: center;

                figure {
                    margin: auto;
                }
            }

            figure {
                
                img {
                    width: rem-calc(200);
                    height: auto;
                }
            }
        }

        .cadre-xx {
            margin-bottom: rem-calc(30);

            .description-forum {
                //padding-top: rem-calc(30);

                @include breakpoint(small only) {
                    padding-top: 0;
                 }
                @include breakpoint(medium only) {
                    padding-top: 0;
                }

                h4 {
                    margin-top: rem-calc(25);
                }
           

                p {

                    &.titre-forum {
                        font-weight: 600;
                        color: $rouge;
                    }
                    &.moderateur-forum, &.speaker-forum {
                        color: $black;
                        margin-bottom: 0;
                        padding-bottom: 0;
                    }
                    &.speaker-forum {
                        margin-bottom: rem-calc(30);
                    }
                }

               
                        @include breakpoint(small only) {
                            .rte {
                                p {
                                    text-align: justify;
                                }
                            }
                         }
                 
            }
        }
    }
}
;@import "sass-embedded-legacy-load-done:731";