/*
 * Personnalisation
 */
/*
#tarteaucitronRoot #tarteaucitronAlertBig .tarteaucitronAllow {
    @include button-style($primary-color, auto, auto);
}

#tarteaucitronRoot #tarteaucitronAlertBig .tarteaucitronDeny {
    @include button-style($secondary-color, auto, auto);
}
*/

;@import "sass-embedded-legacy-load-done:722";