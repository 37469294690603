form {
    &#formremboursement {
        .container_file {
            width: 98%;
            @include shadow-box;
            position: relative;
            margin: rem-calc(15);
        }

        .file {
            flex-direction: column;

                .large-3 {
                    width: 100%;

                        label {
                            padding: 0;
                            margin: 0;
                            text-transform: uppercase;
                        }
                }

                input[type='file'] {
                    padding: 0;
                    margin: 0;
                    color: grey;
                    font-size: rem-calc(15);
                }
                .help-text {
                    padding-top: rem-calc(6);
                    color: grey;
                }
                .error {
                    padding-top: rem-calc(6);
                }
        }

        /* Masquer tous les divs avec id commençant par rfichier_ qui ne sont pas dans un container_file */
        div:not(.container_file) > div[id^="rfichier_"]:not(#rfichier_1):not(#rfichier_1_details) {
            display: none;
        }

        /* Style pour le bouton "+" */
        div {
            &#plusButton {
                cursor: pointer;
                display: inline-block;
                margin-left: 10px; /* Ajuster selon les besoins */
                width: rem-calc(45);
                height: rem-calc(45);
                background-color: $rouge;
                color: $white;
                text-align: center;
                line-height: 1.3;
                font-size: rem-calc(30);
                font-weight: $font-weight-blod;
                border-radius: 50%;
                position: absolute;
                bottom: -20px; // Ajustez selon la taille du bouton pour le placer en bas
                left: 50%; // Positionne le bouton à 50% de la largeur du parent
                transform: translateX(-50%); // Décale le bouton de 50% de sa propre largeur vers la gauche
    
                    &:hover {
                        background-color: $black;
                    }
            }
        }

        button {
            border-radius: rem-calc(25);
            font-size: rem-calc(18);
        }

    }
}



;@import "sass-embedded-legacy-load-done:729";