/* Feuille de style des contenus gérés par le tinyMCE de la console */
.rte {
    ul, ol {
        list-style-position: inside;
    }
    p {

    }
    .grid {
    display: grid;
    }

  .col2 {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;

    @include breakpoint(small only)  {
      grid-template-columns: 1fr;
    }
  }

  .col1-3 {
    grid-template-columns: 1fr 2fr;
    grid-gap: 30px;
  
    @include breakpoint(small only)  {
      grid-template-columns: 1fr;
    }
  }

  .col3-1 {
    grid-template-columns: 2fr 1fr;
    grid-gap: 30px;
  
    @include breakpoint(small only)  {
      grid-template-columns: 1fr;
    }
  }

  .col3 {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    @include breakpoint(small only) {
      grid-template-columns: 1fr;
    }
  }
  
  .col4 {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;


    @include breakpoint(medium down) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint(medium down) {
      grid-template-columns: 1fr;
    }
  }
  .col5 {
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 20px;
    @include breakpoint(medium down) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint(medium down) {
      grid-template-columns: 1fr;
    }
  }
  
  .col6 {
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 20px;
    @include breakpoint(medium down) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include breakpoint(medium down) {
      grid-template-columns: 1fr;
    }
  }
}

;@import "sass-embedded-legacy-load-done:720";