/* Welcome */
#edito-news {
    //background-image: url(../assets/frise.gif);
    //background-repeat: repeat-x;
    //background-position: center rem-calc(-50);
    padding-top: rem-calc(60);

        h2 {
            background: url(../images/bg-h2-rouge.png) no-repeat rem-calc(600 15);
            color: #c71c38;
            text-transform: uppercase;
        }
}

/* Colonne de droite */
.colright {
    .block-col {
        margin:  rem-calc(30 0 80 0) !important;

        h3 {
            //border: $gris solid 1px ;
            box-shadow: rem-calc(0 0 10 0) rgba(0,0,0,.1);
            @include border-radius(0 30px 30px 0);
            padding: rem-calc(10 30 10 30) !important;
            margin:  0 !important;
            font-size: rem-calc(20);
            background-color: $black;
            color: $white;
            display: flex;
            align-items: center;
            gap: rem-calc(5);
            font-weight: 900;
    
            span {
                font-weight: $font-weight-medium;
                color: $rouge;
            }
    
            &:before {
                content: none;
            }
    
            &:first-child {
                margin-top: 0;
            }
    
        }

        .cadre-block {
            margin: rem-calc(15) auto rem-calc(15) auto;
            display: block;
            
            //border: solid rem-calc(1) $gris;
            padding: rem-calc(15 15 0 15);
            //text-align: center;
            //background: $gris-pale;
            //@include shadow-box;

            a {
                &.button {
                    background-color: $rouge;

                    &:hover {
                        background-color: rgba($rouge, .7);
                    }
                }
            }
    
            h4 {
                font-size: rem-calc(22);
                font-weight: $font-weight-blod;
                line-height: 1.2;
                margin: rem-calc(0 0 15 0);
    
                span {
                    font-weight: $font-weight-light;
                }
    
                a {
                    color: $black;
                    display: block;
                    &:hover {
                        color: $rouge;
                    }
                }
            }
    
            &:hover {
                h4 {
                    a {
                        &:hover {
                            color: $rouge;
                        }
                    }
                }
            }
        }

        img {
            @include shadow-box;
            @include transition;

            &:hover {
                @include transition;
                transform: scale(1.03);
            }
        }
    }

    
}

/* Quick access */
#quick-acces {
    background: #f1f1f1;
    padding: 2em;
    overflow: hidden;
    margin-top: rem-calc(20);
    
	h2 {
        font-weight: 800;
        color: #4c4c4b;
        font-size: 3.5rem;
        text-align: center;
        text-transform: uppercase;
    }
    ul {
        padding: 0.3em 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        float: none;
        li {
            list-style: none;
            
            a {
                &.link-quick {
                    display: block;
                    min-width: 215px;
                    border-radius: rem-calc(10);
                    -webkit-border-radius: rem-calc(10);
                    text-align: center;
                    background: white;
                    padding: 1em;
                    margin: 0.5em;
                    -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
                    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
                    -webkit-transition: all 400ms ease-in-out;
                    -moz-transition: all 400ms ease-in-out;
                    -ms-transition: all 400ms ease-in-out;
                    -o-transition: all 400ms ease-in-out;
                    transition: all 400ms ease-in-out;

                    h3 {
                        text-align: center;
                        color: #c51431;
                        font-size: 1.3rem;
                        margin: 0;
                        padding: 0;
                        font-weight: 500 !important;
                        text-transform: uppercase;
                    }
                    p {
                        text-align: center;
                        color: #807e82;
                    }

                    &:hover {
                        -moz-transform: scale(1.1);
                        -webkit-transform: scale(1.1);
                        -o-transform: scale(1.1);
                        -ms-transform: scale(1.1);
                        transform: scale(1.1);
                        -webkit-transition: all 400ms ease-in-out;
                        -moz-transition: all 400ms ease-in-out;
                        -ms-transition: all 400ms ease-in-out;
                        -o-transition: all 400ms ease-in-out;
                        transition: all 400ms ease-in-out;
                    }
                }
            }
        }

        a {
            &.link-quick {
                img {
                    width: 120px;
                    height: auto;
                }

                
            }
        }

        
    }
}

@media only screen and (max-width: 40em) {
    #quick-acces h2 {
        font-size: 2.5rem;
    }
    #quick-acces ul li > a.link-quick > h3 {
        font-size: 1.1rem !important;
    }
}

@media only screen and (min-width: 40.063em) and (max-width: 64.063em) {
    #quick-acces h2 {
        font-size: 2.5rem;
    }
}

;@import "sass-embedded-legacy-load-done:727";